<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title> Claim Reporting ({{ total }}) </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu min-width="50%" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-format-columns</v-icon>
              </v-btn>
            </template>
            <span>Report Columns</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>Report Columns</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <SelectAll
                  dense
                  v-model="selectedClaimReportFields"
                  :items="claimReportFields"
                  label="Claim Columns"
                  item-text="name"
                  multiple
                  chips
                  deletable-chips
                  hide-details
                  return-object
                  @change="updateHeaders"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 1" class="grey--text caption">
                      ({{ selectedClaimReportFields.length }} claim fields selected)
                    </span>
                  </template>
                </SelectAll>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <SelectAll
                  dense
                  v-model="selectedClaimProductReportFields"
                  :items="claimProductReportFields"
                  label="Product Columns"
                  item-text="name"
                  multiple
                  chips
                  deletable-chips
                  hide-details
                  return-object
                  @change="updateHeaders"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 1" class="grey--text caption">
                      ({{ selectedClaimProductReportFields.length }} product fields selected)
                    </span>
                  </template>

                  ></SelectAll
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ClaimTypeField
                  dense
                  :messages="['Enables custom claim fields']"
                  v-model="selectedClaimType"
                  label="Claim Type"
                  @change="fetchClaimFields"
                ></ClaimTypeField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <SelectAll
                  dense
                  :disabled="!selectedClaimType"
                  v-model="selectedClaimFields"
                  :items="claimFields"
                  label="Custom Claim Columns"
                  item-text="name"
                  multiple
                  chips
                  deletable-chips
                  return-object
                  :messages="!selectedClaimType ? ['Requires a selected claim type'] : []"
                  @change="updateHeaders"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 1" class="grey--text caption">
                      ({{ selectedClaimFields.length }} custom claim fields selected)
                    </span>
                  </template>
                </SelectAll>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-form @submit.stop.prevent="getData">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Keyword')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>

      <v-menu min-width="50%" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <ClaimTypeField
                  dense
                  :messages="['Enables custom claim fields']"
                  v-model="selectedClaimType"
                  label="Claim Type"
                  @change="fetchClaimFields"
                ></ClaimTypeField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedClaimType">
              <v-list-item-content>
                <ClaimStageField
                  dense
                  :messages="['Dependent on claim type']"
                  v-model="selectedClaimStage"
                  label="Claim Stage"
                  :claimType="selectedClaimType"
                  @change="getData"
                ></ClaimStageField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <PromotionTypesField
                  dense
                  hide-details
                  v-model="selectedPromotionTypes"
                  label="Promotion Types"
                  @change="getData"
                ></PromotionTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <PromotionsField
                  dense
                  hide-details
                  v-model="selectedPromotions"
                  label="Promotions"
                  @change="getData"
                ></PromotionsField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationsField
                  dense
                  hide-details
                  v-model="selectedOrganizations"
                  label="Company Name"
                  @change="getData"
                  :showKey="true"
                ></OrganizationsField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ParticipantsField
                  dense
                  hide-details
                  v-model="selectedParticipants"
                  label="Participant Name"
                  @change="getData"
                >
                  <template v-slot:selectedItemDescription="{ item }">
                    {{ item.fullName }} - {{ item.participantKey }}
                  </template>
                  <template v-slot:itemDescription="{ item }">
                    {{ item.fullName }} - {{ item.participantKey }}
                  </template></ParticipantsField
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedSubmitted"
                  label="Submitted?"
                  @change="getData"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedApproved"
                  label="Approved?"
                  @change="getData"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedRejected"
                  label="Rejected?"
                  @change="getData"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedClosed"
                  label="Closed?"
                  @change="getData"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            small
            fab
            v-bind="attrs"
            v-on="on"
            @click="onSubmit()"
            :disabled="items.length == 0 || isGeneratingReport"
          >
            <v-icon v-if="!isGeneratingReport">mdi-exit-to-app</v-icon>
            <v-progress-circular indeterminate color="primary" v-if="isGeneratingReport"></v-progress-circular>
          </v-btn>
        </template>
        <span>Export Report ({{ total }})</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <DraggableHeaderTable
        fixed-header
        height="50vh"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :expanded.sync="expanded"
        :single-expand="hasProducts"
        :show-expand="hasProducts"
        @item-expanded="fetchClaimProducts"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        selectable
        :server-items-length="total"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
            <v-data-table
              class="mt-5 mb-5 compact-table"
              :calculate-widths="true"
              v-else
              hide-default-footer
              :headers="selectedClaimProductReportFields"
              :items="expandedClaimProducts[item.id]"
            >
            </v-data-table>
          </td>
        </template>
      </DraggableHeaderTable>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "ClaimReporting",
  metaInfo: {
    title: "Claim Reporting"
  },
  data() {
    return {
      valid: false,
      loading: false,

      reportName: "Claim Report",

      expanded: [],
      expandedClaimProducts: [],
      isGeneratingReport: false,
      search: "",
      selectedClaimReportFields: [],
      selectedClaimProductReportFields: [],
      selectedClaimFields: [],
      selectedClaimType: null,
      selectedClaimStage: null,
      selectedOrganizations: [],
      selectedParticipants: [],
      selectedPromotionTypes: [],
      selectedPromotions: [],
      selectedSubmitted: undefined,
      selectedApproved: undefined,
      selectedRejected: undefined,
      selectedClosed: undefined,

      fieldsSelected: [],
      filterByClaimType: null,
      filterByPromotionTypes: null,
      claimFields: [],
      claimReportFields: [],
      claimProductReportFields: [],
      headers: [],
      items: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      rules: {
        name: [v => !!v || "Report Name is required"]
      }
    };
  },
  methods: {
    onClear() {
      this.search = "";
      this.selectedClaimReportFields = [];
      this.selectedClaimProductReportFields = [];
      this.selectedClaimFields = [];
      this.selectedClaimType = null;
      this.selectedClaimStage = null;
      this.selectedOrganizations = [];
      this.selectedParticipant = [];
      this.selectedPromotions = [];
      this.selectedPromotionTypes = [];
      this.selectedSubmitted = undefined;
      this.selectedApproved = undefined;
      this.selectedRejected = undefined;
      this.selectedClosed = undefined;
      this.getData();
    },
    onSubmit() {
      let count = 0;
      let reportHeaders = [];
      this.isGeneratingReport = true;
      this.headers.forEach(header => {
        if (header.name) {
          reportHeaders.push({
            name: header.name,
            enumValue: header.enumValue,
            typeValue: header.typeValue,
            typeId: header.typeId,
            rankOrder: count++
          });
        }
      });
      this.selectedClaimProductReportFields.forEach(header => {
        reportHeaders.push({
          name: header.name,
          enumValue: header.enumValue,
          typeValue: header.typeValue,
          typeId: header.typeId,
          rankOrder: count++
        });
      });
      let postForm = {
        name: this.reportName,
        type: { name: "CLAIMING" },
        generationType: {
          name: "XLSX"
        },
        filters: this.getFilters(),
        reportHeaders: reportHeaders
      };
      this.$api
        .post("/api/reports/generate", postForm)
        .then(() => {
          this.isGeneratingReport = false;
          this.$router.push({ name: "reportDownloads" });
        })
        .catch(error => {
          this.isGeneratingReport = false;
          console.log(error);
        });
    },
    updateHeaders() {
      this.$nextTick(() => {
        let h = [...this.selectedClaimReportFields, ...this.selectedClaimFields];
        if (this.hasProducts) {
          h = h.filter(v => v.value != "data-table-expand");
          h.unshift({
            text: "Products",
            value: "data-table-expand",
            class: "data-table-expand-header",
            align: "center"
          });
          this.$forceUpdate();
        }
        h = h.map(header => this.translateHeader(header));
        this.headers = h;
        this.getData();
      });
    },
    translateHeader(h) {
      if (h.text) {
        h.text = h.text.replaceAll("Promotion Key", "Promotion Number");
        h.text = h.text.replaceAll("Promotion Name", "Promotion Title");
        h.text = h.text.replaceAll("Promotion Publish Date", "Publish Date");
        h.text = h.text.replaceAll("Company Key", "SAP/Indirect ID");
        h.text = h.text.replaceAll("Participant Key", "Participant ID");
        h.text = h.text.replaceAll("Claim Key", "Claim ID");
        h.text = h.text.replaceAll("Claim Type", "Claim Form Type");
        h.text = h.text.replaceAll("Claim Zero Date", "Zero Sales Date");
        h.text = h.text.replaceAll("Product Key", "Product Number");
        h.text = h.text.replaceAll("Product Serial Number", "Serial Number");
        h.text = h.text.replaceAll("Product Quantity", "Quantity");
        h.text = h.text.replaceAll("Product Payout Award Vehicle", "Product Override Award Vehicle");
      }
      if (h.name) {
        h.name = h.name.replaceAll("Promotion Key", "Promotion Number");
        h.name = h.name.replaceAll("Promotion Name", "Promotion Title");
        h.name = h.name.replaceAll("Promotion Publish Date", "Publish Date");
        h.name = h.name.replaceAll("Company Key", "SAP/Indirect ID");
        h.name = h.name.replaceAll("Participant Key", "Participant ID");
        h.name = h.name.replaceAll("Claim Key", "Claim ID");
        h.name = h.name.replaceAll("Claim Type", "Claim Form Type");
        h.name = h.name.replaceAll("Claim Zero Date", "Zero Sales Date");
        h.name = h.name.replaceAll("Product Key", "Product Number");
        h.name = h.name.replaceAll("Product Serial Number", "Serial Number");
        h.name = h.name.replaceAll("Product Quantity", "Quantity");
        h.name = h.name.replaceAll("Product Payout Award Vehicle", "Product Override Award Vehicle");
      }
      return h;
    },
    getFilters() {
      let filters = {};

      if (this.selectedParticipant.participantType.participantTypeKey == "300") {
        filters.organizationRelatedParticipantReportToParticipant = { id: this.selectedParticipant.id };
      }

      if (this.selectedParticipant.participantType.participantTypeKey == "400") {
        filters.organizationRelatedParticipant = { id: this.selectedParticipant.id };
      }

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.selectedClaimType && this.selectedClaimType.id) {
        filters.claimType = { id: this.selectedClaimType.id };
      }

      if (this.selectedPromotions && this.selectedPromotions.length > 0) {
        filters.promotions = this.selectedPromotions.map(promotion => {
          return { id: promotion.id };
        });
      }

      if (this.selectedClaimStage && this.selectedClaimStage.id) {
        filters.claimStage = { id: this.selectedClaimStage.id };
      }

      if (this.selectedOrganizations && this.selectedOrganizations.length > 0) {
        filters.organizations = this.selectedOrganizations.map(organization => {
          return { id: organization.id };
        });
      }

      if (this.selectedParticipants && this.selectedParticipants.length > 0) {
        filters.participants = this.selectedParticipants.map(participant => {
          return { id: participant.id };
        });
      }

      if (this.selectedSubmitted != undefined) {
        filters.submitted = this.selectedSubmitted;
      }

      if (this.selectedApproved != undefined) {
        filters.approved = this.selectedApproved;
      }

      if (this.selectedRejected != undefined) {
        filters.rejected = this.selectedRejected;
      }

      if (this.selectedClosed != undefined) {
        filters.closed = this.selectedClosed;
      }

      if (this.selectedPromotionTypes && this.selectedPromotionTypes.length > 0) {
        filters.promotionTypes = this.selectedPromotionTypes.map(promotionType => {
          return { id: promotionType.id };
        });
      }

      if (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) {
        filters.promotionTypes = this.filterByPromotionTypes.map(promotionTypeId => {
          return {
            id: promotionTypeId
          };
        });
      }
      return filters;
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = this.getFilters();

      this.$api
        .post(
          "/api/claims/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.items.reduce((p, item) => this.fetchClaimFieldValues(item), Promise.resolve());
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchClaimFieldValues(item) {
      return this.$api.get("/api/claimFieldValues/byClaimId/" + item.id + "?size=100").then(({ data }) => {
        data.content.forEach(claimFieldValue => {
          let value = claimFieldValue.value;
          if (claimFieldValue.claimField.claimFieldType.date === true) {
            value = this.$util.formatDateClient(value, "MM/DD/YYYY", this.selectedClient);
          }
          Vue.set(item, "claimField_" + claimFieldValue.claimFieldId, value);
        });
        return Promise.resolve(item);
      });
    },
    fetchClaimReportFields() {
      this.claimReportFields = [];
      return this.$api.get("/api/types/report/claimReportFields").then(({ data }) => {
        this.claimReportFields = [];
        data.forEach(field => {
          field.description = field.description.replaceAll("Organization", "Company");
          this.claimReportFields.push({
            name: field.description,
            enumValue: field.name,
            typeValue: "ClaimReportFieldType",
            value:
              field.date === true
                ? this.$util.formatDateClient(field.vueValue, "MM/DD/YYYY", this.selectedClient)
                : field.vueValue,
            align: field.align ? field.align.toLowerCase() : "left",
            sortable: false,
            text: field.description
          });
        });
        this.selectedClaimReportFields = this.claimReportFields;
        this.fetchClaimProductReportFields();
      });
    },
    fetchClaimProductReportFields() {
      this.claimProductReportFields = [];
      return this.$api.get("/api/types/report/claimProductReportFields").then(({ data }) => {
        this.claimProductReportFields = [];
        data.forEach(field => {
          field.description = field.description.replaceAll("Organization", "Company");
          this.claimProductReportFields.push({
            name: field.description,
            enumValue: field.name,
            typeValue: "ClaimProductReportFieldType",
            value:
              field.date === true
                ? this.$util.formatDateClient(field.vueValue, "MM/DD/YYYY", this.selectedClient)
                : field.vueValue,
            align: field.align ? field.align.toLowerCase() : "left",
            sortable: false,
            text: field.description
          });
        });
        this.claimProductReportFields.splice(
          this.claimProductReportFields.findIndex(elem => elem.enumValue == "TOTAL_PAYOUT_AMOUNT") + 1,
          0,
          this.claimProductReportFields.splice(
            this.claimProductReportFields.findIndex(elem => elem.enumValue == "PRODUCT_PAYOUT_CURRENCY"),
            1
          )[0]
        );
        this.claimProductReportFields = this.claimProductReportFields.map(header => this.translateHeader(header));
        this.selectedClaimProductReportFields = this.claimProductReportFields;
        this.updateHeaders();
      });
    },
    fetchClaimFields() {
      this.selectedClaimFields = [];
      if (this.selectedClaimType) {
        return this.$api
          .get("/api/claimFields/byClaimTypeId/" + this.selectedClaimType.id + "?size=100&sort=rankOrder,ASC")
          .then(({ data }) => {
            this.claimFields = [];
            data.content.forEach(field => {
              this.claimFields.push({
                name: field.name,
                value: "claimField_" + field.id,
                typeValue: "ClaimField",
                typeId: field.id,
                align: field.claimFieldType.align,
                sortable: false,
                text: field.name
              });
            });
            return this.getData();
          });
      } else {
        return this.getData();
      }
    },
    fetchClaimProducts(obj) {
      if (obj.item.id in this.expandedClaimProducts) {
        return;
      }
      this.loading = true;
      return this.$api
        .get("/api/claimProducts/byClaimId/" + obj.item.id)
        .then(({ data }) => {
          this.expandedClaimProducts[obj.item.id] = data.content;
          for (let elem of this.expandedClaimProducts[obj.item.id]) {
            elem.promotion = obj.item.promotion;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDescendantProp(obj, desc) {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Reports"
      },
      { text: "Claiming Report" }
    ]);

    this.fetchClaimReportFields();
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),
    hasProducts() {
      return this.selectedClaimProductReportFields && this.selectedClaimProductReportFields.length > 0;
    }
  }
};
</script>
